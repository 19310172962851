import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import logoSrc from "../assets/logo_chat.svg";
import { useParams } from "react-router-dom";

const SHOW_MESSAGE_TIME = 6000;

window.debug = false;

export const ProjectionPage = () => {
  const { evento } = useParams();
  const [messaggi, cambiaMessaggi] = useState([]);
  const [messaggiApprovati, cambiaMessaggiApprovati] = useState([]);

  useEffect(() => {
    var messagesRef = firebase.database().ref(`/messaggi/${evento}`);

    messagesRef.on("child_added", function (snapshot) {
      console.log("child_added");
      var messaggio = snapshot.val();
      cambiaMessaggi((oldArray) => {
        const newArray = [...oldArray, messaggio].sort(
          (a, b) => b.timestamp - a.timestamp
        );
        cambiaMessaggiApprovati(newArray.filter((d) => d.approvato));
        return newArray;
      });
    });

    messagesRef.on("child_removed", function (snapshot) {
      console.log("child_removed");
      var messaggio = snapshot.val();
      cambiaMessaggi((oldArray) => {
        const newArray = [];
        oldArray.forEach((d) => {
          if (d.id !== messaggio.id) {
            newArray.push(d);
          }
        });
        newArray.sort((a, b) => b.timestamp - a.timestamp);
        cambiaMessaggiApprovati(newArray.filter((d) => d.approvato));
        return newArray;
      });
    });

    messagesRef.on("child_changed", function (snapshot) {
      console.log("child_changed");
      const messaggio = snapshot.val();
      cambiaMessaggi((oldArray) => {
        const newArray = [...oldArray];
        newArray.forEach((d, i, a) => {
          if (d.id === messaggio.id) a[i] = messaggio;
        });
        cambiaMessaggiApprovati(newArray.filter((d) => d.approvato));
        return newArray;
      });
    });

    messagesRef.onDisconnect();
  }, []);

  const ContainerMessaggio = ({ messaggiApprovati }) => {
    if (!messaggiApprovati.length) {
      return <div>nessun messaggio</div>;
    } else {
      return <Messaggio messaggiApprovati={messaggiApprovati} />;
    }
  };

  return (
    <div>
      <VideoComponent />
      <ContainerMessaggio messaggiApprovati={messaggiApprovati} />
      <img
        src={logoSrc}
        style={{
          width: 120,
          position: "absolute",
          top: 20,
          right: 20,
        }}
      />
    </div>
  );
};

const Messaggio = ({ messaggiApprovati }) => {
  const [fadeProp, setFadeProp] = useState("in");
  const [messaggioDaMostrare, setMessaggioDaMostrare] = useState(null);
  const [indiceCorrente, cambiaindiceCorrente] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const [rotation, setRotation] = useState(0);
  const nextMessage = () => {
    const newIndice = (indiceCorrente + 1) % messaggiApprovati.length;
    cambiaindiceCorrente(newIndice);
  };

  const showNextMessage = (newMessage) => {
    setFadeProp("in");
    setMessaggioDaMostrare(newMessage);
    setTimeout(() => {
      nextMessage();
    }, SHOW_MESSAGE_TIME);
  };

  useEffect(() => {
    const newMessage = messaggiApprovati[indiceCorrente];
    if (newMessage !== messaggioDaMostrare) {
      setFadeProp("out");
      const timeout = setTimeout(() => {
        console.log(newMessage.immagineURL);
        if (newMessage.immagineURL) {
          const img = new Image();
          img.onload = (i) => {
            setImageWidth(img.width > img.height ? 800 : 400);
            setRotation(Math.random() * 10 - 5);
            showNextMessage(newMessage);
          };
          img.src = newMessage.immagineURL;
        } else {
          showNextMessage(newMessage);
        }
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [indiceCorrente]);

  return (
    <div className={"fade-" + fadeProp}>
      <div
        style={{
          position: "absolute",
          fontFamily: "Lineatura",
          width: "100%",
          height: "100vh",
          fontWeight: "bold",
          color: "white",
          display: "flex",
          justifyContent: "space-around",
          alignContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {messaggioDaMostrare && (
          <div>
            {messaggioDaMostrare.immagineURL && (
              <img
                src={messaggioDaMostrare.immagineURL}
                style={{
                  border: "10px solid #ccc",
                  width: imageWidth,
                  transformOrigin: "50% 50%",
                  transform: `rotate(${rotation}deg)`,
                }}
              />
            )}
            <h1
              style={{
                fontSize: messaggioDaMostrare.immagineURL ? 20 : 50,
                textShadow:
                  "0px 0px 10px rgba(0,0,0,100), 0px 0px 10px rgba(0,0,0,100),  0px 0px 10px rgba(0,0,0,100)",
              }}
            >
              {messaggioDaMostrare.testo}
            </h1>
            {messaggioDaMostrare.autore && (
              <p
                style={{
                  fontSize: 20,
                  display: "inline-block",
                  background: "black",
                  padding: "0px 6px",
                  paddingBottom: "4px",
                }}
              >
                da {messaggioDaMostrare.autore}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export const VideoComponent = React.memo(() => {
  return (
    <video autoPlay muted loop id="myVideo">
      <source src={"https://letsswing.it/video.mp4"} type="video/mp4" />
    </video>
  );
});
