import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import firebase from "firebase/compat/app";

import { Stack } from "@mui/system";
import logoSrc from "../assets/logo_chat.svg";
import { Box, Button, CircularProgress, Container, Grid } from "@mui/material";
import { VideoComponent } from "./ProjectionPage";
import { Buttons } from "../Buttons";
import moment from "moment";
import { Logo } from "./HomePage";

export const GalleryPage = () => {
  const { evento } = useParams();
  const [mainMessage, setMainMessage] = useState(null);
  const [messages, setMessages] = useState([]);
  const [eventDetails, setEventDetails] = useState(null);

  useEffect(() => {
    const eventRef = firebase.database().ref(`/eventi/${evento}`);
    eventRef.once("value", function (s) {
      setEventDetails(s.val());
    });
    const messagesRef = firebase.database().ref(`/messaggi/${evento}`);
    messagesRef.once("value", function (snapshot) {
      const messaggiObject = snapshot.val();
      const messaggi = Object.keys(messaggiObject)
        .map((d) => {
          return messaggiObject[d];
        })
        .filter((d) => d.approvato)
        .sort((a, b) => a.timestamp - b.timestamp);
      setMessages(messaggi);
    });
  }, [evento]);

  if (!eventDetails) return <></>;
  return (
    <div style={{ background: "black" }}>
      <Container
        style={{
          fontFamily: "Lineatura",
          fontWeight: "bold",
          color: "white",
          zIndex: 1,
          position: "relative",
          top: 0,
        }}
      >
        {mainMessage == null ? (
          <GridView
            eventDetails={eventDetails}
            setMainMessage={setMainMessage}
            messages={messages}
          />
        ) : (
          <ContenitoreMessaggio
            messages={messages}
            setMainMessage={setMainMessage}
            mainMessage={mainMessage}
          />
        )}
      </Container>
    </div>
  );
};

const GridView = ({ eventDetails, messages, setMainMessage }) => {
  return (
    <>
      <h1
        style={{
          fontSize: "4.6rem",
        }}
      >
        {eventDetails.title}
      </h1>
      {messages.length && (
        <GridThumbs messages={messages} setMainMessage={setMainMessage} />
      )}
    </>
  );
};

const Timestamp = ({ message }) => {
  return (
    <p style={{ color: "#666" }}>
      alle {moment(message.timestamp).format("HH:MM")}
    </p>
  );
};

const GridThumbs = ({ messages, setMainMessage }) => {
  const getElement = (message) => {
    let element;
    if (message.immagineURL) {
      element = (
        <>
          <img
            onClick={() => {
              setMainMessage(message);
            }}
            width="100%"
            height="auto"
            style={{ cursor: "pointer" }}
            sx={{ border: "4px solid white" }}
            src={message.immagineURL}
            alt={message.message}
          />
          {message.testo && (
            <h1
              style={{
                fontSize: "1.2rem",
                textAlign: "center",
                textShadow:
                  "0px 0px 10px rgba(0,0,0,100), 0px 0px 10px rgba(0,0,0,100),  0px 0px 10px rgba(0,0,0,100)",
              }}
            >
              {message.testo}
            </h1>
          )}
        </>
      );
    } else {
      element = (
        <h1
          onClick={() => {
            setMainMessage(message);
          }}
          style={{
            fontSize: "1.2rem",
            textAlign: "center",
            textShadow:
              "0px 0px 10px rgba(0,0,0,100), 0px 0px 10px rgba(0,0,0,100),  0px 0px 10px rgba(0,0,0,100)",
          }}
        >
          {message.testo}
        </h1>
      );
    }

    return (
      <div style={{ textAlign: "center" }}>
        {element}
        {message.autore && (
          <div>
            <p
              style={{
                fontSize: 20,
                margin: 0,
                display: "inline-block",
                background: "black",
                padding: "0px 6px",
                paddingBottom: "4px",
              }}
            >
              da {message.autore}
            </p>
          </div>
        )}
        <Timestamp message={message} />
      </div>
    );
  };
  return (
    <Grid xs={12} sm={12} container spacing={2}>
      {messages.map((message, index) => (
        <Grid item xs={6} sm={4} key={index}>
          <Box
            my={4}
            p={2}
            sx={{ overflow: "hidden", height: "100%" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {getElement(message)}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

const ContenitoreMessaggio = ({ messages, setMainMessage, mainMessage }) => {
  const [indiceCorrente, cambiaindiceCorrente] = useState(
    messages.findIndex((d) => d === mainMessage)
  );
  const messaggioDaMostrare = messages[indiceCorrente];
  return (
    <Stack sx={{ zIndex: 1 }} alignItems={"center"} justifyContent={"center"}>
      {messaggioDaMostrare.immagineURL && (
        <ImagePlaceholder
          messaggioDaMostrare={messaggioDaMostrare}
          style={{
            maxWidth: "100%",
            maxHeight: "80vh",
          }}
        />
      )}
      <Messaggio messaggioDaMostrare={messaggioDaMostrare} />

      {Buttons(indiceCorrente, cambiaindiceCorrente, messages, setMainMessage)}
    </Stack>
  );
};

function ImagePlaceholder({ messaggioDaMostrare }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    console.log("go");
    setImageLoaded(false);

    if (messaggioDaMostrare.immagineURL) {
      const image = new Image();
      image.onload = () => {
        setImageLoaded(true);
      };
      image.src = messaggioDaMostrare.immagineURL;
    } else {
      setImageLoaded(true);
    }
  }, [messaggioDaMostrare.immagineURL]);

  if (imageLoaded) {
    return (
      <div>
        {messaggioDaMostrare.immagineURL && (
          <img
            alt=""
            src={messaggioDaMostrare.immagineURL}
            style={{
              maxWidth: "100%",
              maxHeight: "65vh",
            }}
          />
        )}
      </div>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
}

const Messaggio = ({ messaggioDaMostrare }) => {
  return (
    <>
      <h1
        style={{
          fontSize: messaggioDaMostrare.immagineURL ? 20 : 50,
          textShadow:
            "0px 0px 10px rgba(0,0,0,100), 0px 0px 10px rgba(0,0,0,100),  0px 0px 10px rgba(0,0,0,100)",
        }}
      >
        {messaggioDaMostrare.testo}
      </h1>
      {messaggioDaMostrare.autore && (
        <div>
          <p
            style={{
              fontSize: 20,
              margin: 0,
              display: "inline-block",
              background: "black",
              padding: "0px 6px",
              paddingBottom: "4px",
            }}
          >
            da {messaggioDaMostrare.autore}
          </p>
        </div>
      )}
      <Timestamp message={messaggioDaMostrare} />
    </>
  );
};
