import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { CircularProgress, Container, Stack, Typography } from "@mui/material";
import logoSrc from "../assets/logo_chat.svg";
import moment from "moment";

export const HomePage = () => {
  const [eventi, cambiaEventi] = useState([]);

  useEffect(() => {
    var messagesRef = firebase.database().ref(`/eventi`);

    messagesRef.once("value", function (snapshot) {
      const eventiObject = snapshot.val();
      console.log(eventiObject);
      const e = Object.keys(eventiObject)
        .map((d) => {
          return { key: d, ...eventiObject[d] };
        })
        .sort((a, b) => {
          return parseInt(a.timestamp) - parseInt(b.timestamp);
        })
        .map((d, i) => {
          const color = `hsl(${i * 10}, 75%, 70%`;
          return (
            <div key={d.key}>
              <a
                style={{
                  textDecoration: "none",
                  display: "inline-block",
                  color,
                  fontSize: "1.5rem",
                  fontFamily: "Lineatura",
                  borderBottom: `2px solid ${color}`,
                }}
                href={`/eventi/${d.key}`}
              >
                {d.title}
              </a>
              <p>{moment(parseInt(d.timestamp)).format("DD/MM/YYYY")}</p>
            </div>
          );
        });
      cambiaEventi(e);
    });
  }, []);

  if (!eventi) return <div></div>;
  return (
    <Container maxWidth="md">
      <Logo />
      <Stack
        spacing={2}
        sx={{
          paddingTop: 20,
        }}
      >
        <Typography
          variant="p"
          style={{
            color: "white",
            fontFamily: "Lineatura",
          }}
        >
          I nostri eventi
        </Typography>
        {eventi.length === 0 && <CircularProgress size={20} />}
        {eventi}
      </Stack>
    </Container>
  );
};

export const Logo = () => {
  return (
    <img
      alt="logo"
      src={logoSrc}
      style={{
        width: 120,
        position: "absolute",
        zIndex: 2,
        top: 20,
        right: 20,
      }}
    />
  );
};
