import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { Stack } from "@mui/system";
import { useParams } from "react-router-dom";
import { Checkbox } from "@mui/material";

function CheckboxListSecondary({ messaggi, evento, onChecked }) {
  return (
    <Stack spacing={4}>
      {messaggi.map((m) => {
        const labelId = `checkbox-list-secondary-label-${m.timestamp}`;
        return (
          <Stack spacing={3} direction="row" key={m.timestamp}>
            <Checkbox
              edge="end"
              onChange={() => onChecked(m)}
              checked={m.approvato}
              inputProps={{ "aria-labelledby": labelId }}
            />

            <div
              style={{
                background: `url("${m.immagineURL}")`,
                backgroundSize: "cover",
                width: 50,
                height: 50,
              }}
            />

            <p style={{ maxWidth: 200, width: 200 }}>
              {`"${m.testo}"`}
              {m.autore && <span>da {m.autore}</span>}
            </p>
            <IconButton
              variant="outlined"
              onClick={() => {
                console.log(`messaggi/${evento}/${m.id}`);
                firebase.database().ref(`messaggi/${evento}/${m.id}`).remove();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      })}
    </Stack>
  );
}

export const AdminPage = () => {
  const [messaggi, cambiaMessaggi] = useState([]);
  const { evento } = useParams();

  useEffect(() => {
    // Get a reference to the messages node in the Realtime Database
    var messagesRef = firebase.database().ref(`/messaggi/${evento}`);
    console.log(messagesRef);

    messagesRef.on("value", function (snapshot) {
      var messaggi = snapshot.val();
      if (messaggi) {
        messaggi = Object.keys(messaggi).map((d) => messaggi[d]);
        cambiaMessaggi(messaggi);
      }
    });

    messagesRef.on("child_changed", function (snapshot) {
      messagesRef.once("value", function (snapshot) {
        var messaggi = snapshot.val();
        if (messaggi) {
          messaggi = Object.keys(messaggi).map((d) => messaggi[d]);
          cambiaMessaggi(messaggi);
        }
      });
    });

    messagesRef.on("child_removed", function (snapshot) {
      messagesRef.once("value", function (snapshot) {
        var messaggi = snapshot.val();
        if (messaggi) {
          messaggi = Object.keys(messaggi).map((d) => messaggi[d]);
          console.log("sdaf");
          cambiaMessaggi(messaggi);
        } else {
          cambiaMessaggi([]);
        }
      });
    });
  }, []);

  let element;
  if (!messaggi.length) {
    element = <div>nessun messaggio</div>;
  } else {
    element = (
      <CheckboxListSecondary
        evento={evento}
        messaggi={messaggi}
        onChecked={(m) => {
          firebase
            .database()
            .ref(`/messaggi/${evento}/` + m.id)
            .update({
              approvato: !m.approvato,
            });
        }}
      />
    );
  }

  return (
    <Stack>
      <h2>Admin</h2>
      {element}
    </Stack>
  );
};
