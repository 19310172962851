import logoSrc from "../assets/logo_chat.svg";
import React, { useState } from "react";
import firebase from "firebase/compat/app";
import TextField from "@mui/material/TextField";
import { Container, Stack } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import Countdown from "../Countdown";
import ImageUpload from "../ImageUpload";
import { useParams } from "react-router-dom";

export const MyFormPage = () => {
  const { evento } = useParams();
  const [mandato, setMandato] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [immagineURL, cambiaimmagineURL] = useState(null);
  const { register, handleSubmit, reset } = useForm();
  const onSubmit = ({ testo, autore }, e) => {
    reset("", {
      keepValues: false,
    });
    setMandato(true);
    cambiaimmagineURL(null);
    var postListRef = firebase.database().ref(`messaggi/${evento}`);
    var newPostRef = postListRef.push();
    const update = {
      id: newPostRef.key,
      autore,
      testo,
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      approvato: true,
    };
    if (immagineURL) update.immagineURL = immagineURL;
    newPostRef.set(update);
  };

  const onImageURLSet = (url) => {
    cambiaimmagineURL(url);
  };

  if (mandato) {
    return (
      <div
        style={{
          fontFamily: "Lineatura",
          height: "100vh",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Typography
          style={{
            fontFamily: "Lineatura",
          }}
          variant="h2"
        >
          mandato!
        </Typography>
        <h1 style={{ fontFamily: "LineaturaLight" }}>
          puoi rimandere un altro messaggio in...
          <Countdown onComplete={() => setMandato(false)} duration={5} />
        </h1>
      </div>
    );
  }
  return (
    <Container maxWidth="sm" style={{ color: "white", marginTop: 20 }}>
      <div style={{ textAlign: "center" }}>
        <img
          alt="logo"
          src={logoSrc}
          style={{
            width: 200,
            marginBottom: 20,
          }}
        />
        <Typography
          variant="h5"
          style={{ marginBottom: 40, fontFamily: "Lineatura" }}
        >
          Manda un messaggio!
        </Typography>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <TextField
            multiline
            rows={4}
            {...register("testo")}
            id="outlined-basic"
            label="messaggio"
            variant="outlined"
          />
          <TextField
            {...register("autore")}
            id="outlined-basic"
            label="da"
            variant="outlined"
          />
          <ImageUpload
            onImageUploading={() => {
              setImageUploading(true);
            }}
            onImageURLSet={(b) => {
              onImageURLSet(b);
              setImageUploading(false);
            }}
          />
          {!imageUploading && (
            <Button
              className="okbutton"
              type="submit"
              style={{
                padding: "30px 0px",
                fontSize: 20,
                fontFamily: "Lineatura",
              }}
              variant="contained"
            >
              ok
            </Button>
          )}
        </Stack>
      </form>
    </Container>
  );
};
