import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/system";

export function Buttons(
  indiceCorrente,
  cambiaindiceCorrente,
  messaggi,
  setMainMessage
) {
  return (
    <Stack
      style={{
        display: "flex",
        width: "100vw",
      }}
      direction="row"
    >
      <Stack
        style={{
          display: "flex",
          width: "70vw",
          margin: "0 auto",
          justifyContent: "space-between",
        }}
        direction="row"
      >
        {indiceCorrente > 0 && (
          <IconButton onClick={() => cambiaindiceCorrente(indiceCorrente - 1)}>
            <ArrowBackIcon style={{ fontSize: "3rem", color: "#ddd" }} />
          </IconButton>
        )}

        <IconButton onClick={() => setMainMessage(null)}>
          <CloseIcon style={{ fontSize: "3rem", color: "#ddd" }} />
        </IconButton>

        {indiceCorrente < messaggi.length - 1 && (
          <IconButton onClick={() => cambiaindiceCorrente(indiceCorrente + 1)}>
            <ArrowForwardIcon style={{ fontSize: "3rem", color: "#ddd" }} />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
}
