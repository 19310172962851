import { useEffect, useRef, useState } from "react";

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function Countdown({ duration, onComplete }) {
  // Initialize the countdown timer with the duration prop
  const [timeLeft, setTimeLeft] = useState(duration);

  // Decrement the timer by 1 second every 1000ms
  useInterval(() => {
    if (timeLeft === 1) {
      onComplete();
    } else {
      setTimeLeft(timeLeft - 1);
    }
  }, 1000);
  let seconds = timeLeft % 60;
  return <span>{seconds}</span>;
}

export default Countdown;
