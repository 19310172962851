import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { Stack } from "@mui/system";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const IDLE = 0;
const IMAGE_SET = 1;
const UPLOADING = 2;
const DONE = 3;

function ImageUpload({ onImageURLSet, onImageUploading }) {
  const { evento } = useParams();
  const [state, setState] = useState();
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [progress, setProgress] = useState(0);

  const handleChange = (e) => {
    if (e.target.files[0]) {
      // setState(1);
      let image = e.target.files[0];
      const imgRef = `images/${evento}/${uuidv4()}_${image.name}`;
      onImageUploading(true);
      const uploadTask = firebase.storage().ref(imgRef).put(image, {
        cacheControl: "public,max-age=3000000",
        contentType: "image/jpeg",
      });

      setState(UPLOADING);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          firebase
            .storage()
            .ref(imgRef)
            .getDownloadURL()
            .then((url) => {
              setImageUrl(url);
              setProgress(0);
              setImage(null);
              onImageURLSet(url);
              setState(3);
            });
        }
      );
    }
  };

  let componente;
  switch (state) {
    default:
    case IDLE:
      componente = (
        <Stack
          style={{ justifyContent: "center", alignItems: "center" }}
          direction="row"
        >
          <input
            style={{ display: "none" }}
            id="camera-input"
            multiple
            type="file"
            accept="image/*"
            capture="camera"
            onChange={handleChange}
          />
          <label htmlFor="camera-input">
            <Button style={{ padding: 20, width: "100%" }} component="span">
              scatta una foto
            </Button>
          </label>
          <input
            style={{ display: "none" }}
            id="gallery-input"
            multiple
            type="file"
            accept="image/*"
            onChange={handleChange}
          />
          <label htmlFor="gallery-input">
            <Button style={{ padding: 20, width: "100%" }} component="span">
              Aggiungi un'immagine
            </Button>
          </label>
        </Stack>
      );
      break;
    case IMAGE_SET:
      componente = (
        <Stack
          spacing={2}
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <img style={{ width: 100 }} src={URL.createObjectURL(image)} />
          <Stack
            spacing={4}
            style={{ width: "100%", justifyContent: "center" }}
            direction="row"
          >
            <Button
              onClick={() => {
                setState(IDLE);
              }}
            >
              riprova
            </Button>
            <Button>carica</Button>
          </Stack>
        </Stack>
      );
      break;

    case UPLOADING:
      componente = (
        <div style={{ width: "100%" }}>
          <p style={{ fontFamily: "Lineatura" }}>un attimo sto caricando...</p>
          <progress style={{ width: "100%" }} value={progress} max="100" />
        </div>
      );
      break;

    case DONE:
      componente = (
        <div>
          <img style={{ width: 200 }} src={imageUrl} />
        </div>
      );
  }

  return componente;
}

export default ImageUpload;
