import "./App.css";
import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/analytics";
import { ProjectionPage } from "./routes/ProjectionPage";
import { MyFormPage } from "./routes/MyFormPage";
import { AdminPage } from "./routes/AdminPage";
import { GalleryPage } from "./routes/GalleryPage";
import { HomePage } from "./routes/HomePage";
import { Button, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import EXIF from "exif-js";

const firebaseConfig = {
  apiKey: "AIzaSyBWE1l8WV_7eyKT-PMu0Kq2w_WiV0SUhJw",
  authDomain: "messaggiswing.firebaseapp.com",
  databaseURL:
    "https://messaggiswing-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "messaggiswing",
  storageBucket: "messaggiswing.appspot.com",
  messagingSenderId: "983131964310",
  appId: "1:983131964310:web:5ef430e42c42d2dfe253b7",
  measurementId: "G-1CBJZ3B48E",
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

function parseDate(s) {
  var b = s.split(/\D/);
  return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
}

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: "black",
  },
});

const Upload = () => {
  const { evento } = useParams();
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleUploadFiles = (files) => {
    let index = 0;

    function upload() {
      const image = files[index];
      EXIF.getData(image, function () {
        var time = EXIF.getTag(this, "DateTime");
        if (time) {
          const timestamp = new Date(parseDate(time)).getTime();
          const uploadTask = firebase
            .storage()
            .ref(`images/${evento}/${image.name}`)
            .put(image, {
              cacheControl: "public,max-age=3000000",
              contentType: "image/jpeg",
            });

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              console.log(progress);
            },
            (error) => {
              console.log(error);
            },
            (d) => {
              // all done
              console.log(d);
              // var postListRef = firebase.database().ref(`messaggi/${evento}`);
              // var newPostRef = postListRef.push();
              // const update = {
              //   id: newPostRef.key,
              //   timestamp,
              //   approvato: true,
              //   immagineURL: "",
              // };

              // newPostRef.set(update);

              // if (++index < files.length) {
              //   upload();
              // }
            }
          );
        }
      });
    }

    upload();
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  return (
    <div className="App">
      <input
        id="fileUpload"
        type="file"
        multiple
        accept="image/jpg"
        onChange={handleFileEvent}
      />

      <Button variant="contained">Upload Files</Button>

      <div className="uploaded-files-list">
        {uploadedFiles.map((file) => (
          <div>{file.name}</div>
        ))}
      </div>
    </div>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:evento" element={<MyFormPage />} />
        <Route path="/eventi/:evento" element={<GalleryPage />} />
        <Route path="proiezione/:evento" element={<ProjectionPage />} />
        <Route path="admin/:evento" element={<AdminPage />} />
        <Route path="upload/:evento" element={<Upload />} />
      </Routes>
    </Router>
  </ThemeProvider>
);
